import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Layout from '../components/layout'

import AddToSlackButton from 'components/buttons/add-to-slack'
import SecondaryParagraph from 'components/text/secondary-paragraph'
import reviewFlowJpg from '../images/review-flow-80.jpg'

const HowItWorks = () => (
  <>
    <Layout>
      <Container>
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <h1
            css={css`
              margin-bottom: 2rem;
              margin-top: 1rem;
              font-size: 1rem;
              color: #848484;
            `}
          >
            See how Toast integrates GitHub into Slack
          </h1>
        </div>
        <img src={reviewFlowJpg} alt="main content" />

        <div
          css={css`
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
          `}
        >
          <AddToSlackButton size={60} />
          <SecondaryParagraph>
            Toast cannot read your Slack messages.
          </SecondaryParagraph>
        </div>
      </Container>
    </Layout>
  </>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export default HowItWorks
