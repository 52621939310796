import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { rem } from 'utils/typography'
import { ReactComponent as SlackIcon } from 'images/slack.svg'
import { colors } from 'theme'

const SlackButton = styled.a`
	box-sizing: border-box;
	border-radius: 10px;
	background-color: white;
	padding: 0;
	margin: 0;

	display: flex;
	flex-direction: row;
	align-items: center;

	overflow: hidden;
	position: relative;
	transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	letter-spacing: ${ rem(0.2) };
	font-weight: 700;
	text-align: left;
	text-decoration: none;
	pointer-events: auto;
	color: ${ colors.textHsla };

	${ props => css`
		height: ${ rem(props.size) };
		padding-right: ${ rem(props.size / 3) };
		border: ${ props.borderWidth }px solid silver;
		font-size: ${ rem(props.size / 2.5) };
	` }

	&:active,
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${ colors.primary };
		${ props => css`
			border: ${ props.borderWidth }px solid ${ colors.primary };
		` }

		cursor: pointer;
		outline: none;
	}
`

const SLACK_CLIENT_ID = process.env.GATSBY_SLACK_CLIENT_ID

const AddToSlackButton = ({ size = 50, className, text = 'Add to Slack', authVersion = 'v2' }) => {
	const borderWidth = 2
	const scopesV1 =
		'bot,chat:write:bot,channels:read,commands,groups:read,users.profile:read,team:read,users:read,users:read.email'
	const slackAuthUrlV1 =
		`https://slack.com/oauth/authorize?client_id=` + `${ SLACK_CLIENT_ID }&scope=${ scopesV1 }&state=authV1`

	const scopesV2 =
		'app_mentions:read,channels:read,chat:write,chat:write.customize,commands,groups:read,' +
		'im:history,im:read,im:write,reactions:read,reactions:write,' +
		'team:read,users.profile:read,users:read,users:read.email' +
		'&user_scope=users.profile:read,users:read,users:read.email'
	const slackAuthUrlV2 =
		`https://slack.com/oauth/v2/authorize` + `?client_id=${ SLACK_CLIENT_ID }&scope=${ scopesV2 }`

	const slackAuthUrl = authVersion === 'v2' ? slackAuthUrlV2 : slackAuthUrlV1

	return (
		<div
			css={css`
				display: inline-block;
			`}
		>
			<SlackButton href={slackAuthUrl} borderWidth={borderWidth} size={size}>
				<div
					css={css`
						width: ${ rem(size - borderWidth) };
						height: ${ rem(size - borderWidth) };
						background-color: white;
						transition: background-color 0.3s;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
					`}
				>
					<SlackIcon
						css={css`
							height: 100%;
							position: relative;
							top: -1px;
						`}
					/>
				</div>

				<div>{text}</div>
			</SlackButton>
		</div>
	)
}

export default AddToSlackButton
