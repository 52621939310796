import styled from '@emotion/styled'
import { fontSizes } from 'utils/typography'

const SecondaryParagraph = styled.p`
  margin-top: 0rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: ${ fontSizes.small2 };
`

export default SecondaryParagraph
